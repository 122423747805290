const config = {
    host: (process.env.NODE_ENV === 'production') ? 'https://bimo.dvgeo.app' : 'http://192.168.100.106:3056',
    homePage: (process.env.NODE_ENV === 'production') ? 'https://bimo.dvgeo.app' : 'http://192.168.100.106:3056',
    api: 'api/',
    apiSocket: 'bimo::1.2',
    nameDir: 'bimo',
    package: 'mx.bimo.web',
    version: '1.0.0',
    googleAPIKey: 'AIzaSyDMwUi1VygULpPNKoTmOORCq124InPdgRY',
    appName: 'Bimo',
    provider: '',
    colorPrimary: '#9900FF',
    colorDark: '#000000',
    colorAccent: '#ccc',
    colorTextPrimary: '#fff',
    colorTextDark: '#000',
    playStorePassengerId: 'mx.bimo.passenger',
    playStoreDriverId: 'mx.bimo.driver',
    appStorePassengerId: '',
    appStoreDriverId: '',
    email: "carlos.pfz@icloud.com",
};
export default config;
